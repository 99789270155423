import React, { Component } from 'react'
//COMPONENTS
import Header from './components/Header'
import MenuButtons from './components/Menu-buttons'
import Footer from './components/Footer'
//CSS
import 'bootswatch/dist/slate/bootstrap.min.css';
import './App.css';


class App extends Component {

 handleChangeSrc = event => {
  const src = document.body
  const srcBg = event.target.value
  src.style.backgroundImage = `url(${srcBg})`
  const policeSf = event.target.style.fontFamily
  src.style.fontFamily = policeSf  
}

 render () {
  
  return (
    /*<div className="App" style={{ backgroundImage: `url(${backG})`, fontFamily: `${police}`}}>*/
    <div className="App">
      <Header />
      <div className="choix container">
        <p className="pbg" >Envie de personnaliser, choisis ton univers :</p>
      </div> 
      <MenuButtons />
      <main className="container">
        <section className="pbg">
          <h1>Qu’est-ce qu’un Jeu de Rôle ?</h1>
          <article>
          <p>
            <span className="italic">Un jeu de rôles est une technique ou activité, par laquelle une personne interprète le rôle d'un personnage (réel ou imaginaire) dans un environnement fictif...</span>ouais bon on ne va pas tous faire avec Wikipédia sinon on risque de s’ennuyer. Souvent le cliché du jeu de rôle, c’est un groupe de personne autour d’une table jouant dans la cave. Alors déjà ce n’est pas qu’un cliché, ça peut arriver, mais concrètement qu’est-ce que c’est ?
          </p>
          <p>
            Le jeu de rôle c’est un jeu de société à caractère associatif permettant à des amis ou de futurs amis de se retrouver, oui autour d’une table soyons francs par terre ce n’est pas terrible, afin de rigoler partager un très bon moment. Il s’agit simplement de mettre en scène des joueurs qui vont incarnés des personnages dans un monde imaginaire ou historique, le tout diriger par un conteur plus précisément le Maître du jeu.
          </p>
          <h3>Il y a quatre points principaux à présenter du JDR (Jeux de Rôles)</h3>
          <p>
            Tout d’abord, le conteur évoqué au-dessus, alias <span className="bold">le Maître du Jeu</span> ou le Meneur de Jeu alias le MJ. Le rôle du MJ est de préparer l’histoire dans laquelle les personnages vont jouer, de préparer les situations auxquelles ils seront confrontés. Il a donc plusieurs rôles, scénariste, acteur-joueur en incarnant les personnes non-joueur (PNJ), metteur en scène en décrivant les lieux, les ambiances, l’univers, etc…Le tout en gérant les joueurs, qu’il faut gérés, d’autant plus si vous jouez avec des amis qui s’égare. La menace en points d’expérience il n’y a pas mieux !
          </p>
          <p>
            Ensuite, <span className="bold">les personnages joueurs</span> (PJ), chacun va construire puis incarner un rôle. Il va sous supervision du MJ, oui il ne vous lâchera jamais (haha), créer l’apparence, la fonction, l’origine du personnage. En revanche, les caractéristiques et/ou compétence sont obtenues aléatoirement en lançant les dés selon des règles qui différent d’un JDR à l’autre.
          </p>
          <p>
            Dans le même registre, nous trouvons donc <span className="bold">les personnages non-joueurs</span> (PNJ) qui sont imaginés et interprétés par le MJ, qui interviennent plus ou moins dans un scénario.
          </p>
          <p>
            Et enfin, dernier point qui est important, <span className="bold">les dés</span>. Il y a les dés classiques que tout le monde connaît à 6 faces, mais vous trouverez aussi les dés à 4, 8, 10, 12, et 20 faces. Les dés c’est le hasard, la chance qui va intervenir pour savoir si vous réussissez ou non des actions que voudrez entreprendre et c’est ce qui en fait la magie. Sur un lancer de dés, vous craindrez le « Fumble » qui signifie tout simplement avoir raté son jet de manière lamentable mais qui feras le plus grand plaisir du MJ, il est toujours là !!! Mais vous allez souhaiter faire une « Réussite Critique », qui signifie que vous avez réussi avec succès votre action, là bizarrement le MJ vous l’entendrez pas !
          </p>
          <p>
            Donc si tu veux vivre l’expérience du JDR il te faut des dés, des feuilles de papier, des crayons, une gomme… et un peu d’imagination ! Mais ne t'en fais pas, certains JDR ne nécessiteront que ton imagination !
          </p>
          <p>
            Sur ce site tu pourras voir les parties auxquelles je joue entant que PJ mais aussi les parties que je maîtrise entant que MJ. Et si tu veux en savoir plus contacte-moi !
          </p>
          </article>
        </section>
      </main>
      <Footer />
    </div>
  );
 };
}

export default App;
