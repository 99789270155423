// Liste de Jeu entant que joueur
const jeu = {
    jeux1: {
      nom: 'Warhammer',
      edition: '2ème édition',
      image: 'warhammer2.jpg',
      univers: 'Médiéval/Fantastique',
      campagne: 'L\'avènement de Khaine',
      description: 'Le Vieux Monde. Un endroit sombre et angoissant, empli de périls et déchiré par la guerre. Des fourmillantes cités de l’Empire aux forêts hantées par les elfes en passant par les pics abrupts des Montagnes du Bord du Monde où les nains luttent contre les gobelins et leurs infâmes cousins, une ombre s’étend sur le monde, l’ombre sinistre et corruptrice du Chaos. \nL’Empire, la plus grande nation du Vieux Monde, s’évertue à empêcher cette funeste marée de submerger ses domaines. Mais l’ennemi se trouve aussi au coeur de ses frontières. Les adeptes de cultes dépravés cherchent à provoquer sa chute, les impitoyables hommes-bêtes rôdent dans les forêts et les infâmes skavens propagent la peste et la maladie depuis les tréfonds de leurs tanières souterraines. \nDans Warhammer, le Jeu de Rôle, vous serez les héros improbables d’aventures périlleuses se déroulant dans un monde cruel. Vous vous aventurerez dans les recoins les plus sombres de l’Empire et vous combattrez des menaces que d’autres ne peuvent ou ne veulent affronter. Vous mourrez probablement seul, abandonné de tous dans un bouge sordide et suintant, mais peut-être survivrez-vous aux ignobles mutants, aux maladies abominables, à des complots insidieux et aux sombres pouvoirs du Chaos. \nEt peut-être serez-vous alors récompensé par la destinée et entrerez-vous dans la légende de Warhammer !',
      personnage: 'Nom: Valentino Auditore. \nÂge: 26ans. \nLieu de naissance: Luccini. \nLieu de résidence: Luccini. \nRace: Humain. \nRôle: Dueliste Estallien. \nSignes distinctifs: Balafre à l\'oeil, démarche atypique.'
    },
    jeux2: {
      nom: 'Loup-Garou',
      edition: 'Les Déchus',
      image: 'loup-garou-les-dechus.jpg',
      univers: 'Contemporain/Fantastique',
      campagne: 'Marvejols 1918',
      description: 'Loup-Garou : les Déchus est un jeu de rôle contemporain-fantastique dans lequel les personnages sont des lycanthropes chargés de préserver l’équilibre entre notre monde et le monde des esprits. Ils doivent aussi créer et protéger le domaine de leur meute, car les loups sont des créatures très territoriales. Ils sont ceux que la Lune a maudit, ceux qui sont condamnés à changer de forme et à préserver l’équilibre entre les mondes. Ce sont les loups-garous, en proie à leur fureur et à leur condition ambiguë, à mi-chemin entre l\'homme et la bête. Comme symbole de la lune, ils sont aussi très vulnérables aux armes en argent. \nA l\'aube des temps, Pangée était un monde d\'équilibre : les êtres de chair pouvaient arpenter les terres spirituelles, alors que les esprits exploraient librement le monde physique. Mais Père Loup veillait à ce que chacun respecte les règles et qu\'aucun ne perturbe l\'harmonie. Il était si sage et si redoutable que Luna, l\'esprit de la lune, s\'éprit de lui et le rejoignit sur terre. De leur union naquirent huit enfants, hybrides de chair et d\'esprit : les Premiers-Nés. \nDe leur père ils héritèrent la force et l\'instinct du loup, et de leur mère la faculté de changer de forme. Ces premiers loups-garous, les Uratha, eurent pour charge de seconder Père Loup dans son rôle. Mais lorsque celui-ci s\'affaiblit, que les esprits affamés se multiplièrent, répandant la souffrance, les Uratha choisirent de trahir leur père pour le remplacer par un chef plus fort. Ce parricide leur valut la malédiction de leur mère et provoqua la Chute qui sépara les mondes spirituels et physiques. Cette légende est celle que se transmettent de génération en génération les Déchus, pour expliquer leur origine et leur but.',
      personnage: 'Nom: Benjamin. \nÂge: 29ans. \nLieu de naissance: Campagne française. \nLieu de résidence: Marvejols. \nRace: Loup-garou. \nTribu: Chasseur des ténèbres. \nMétier: Garde Chasse. \nSpécialité: Discrétion, Inflitration, Sniper'
    },
    jeux3: {
      nom: 'L\'Appel de Cthulhu',
      edition: 'Les Masques de Nyarlathotep',
      image: 'Nyarlathotep.jpg',
      univers: 'Moderne/Fantastique',
      campagne: 'Les Masques de Nyarlathotep',
      description : '"J\'ai vu la rue remplie d\'hommes marchant lourdement dans la nuit, tout en chuchotant apeurés et se rendant au même endroit. Je suis allé avec eux, effrayé et pourtant désireux de voir et d`\'entendre le grand, l\'obscur, l\'inexprimable Nyarlathotep." \nEn avril 1919, Roger Carlyle, un richissime play-boy New-Yorkais, décide de financer et d\'organiser une expédition archéologique. Accompagné des plus éminents égyptologues, photographes et médecins du moment, l\'expédition fait une escale de quelques semaines à Londres afin de préparer les fouilles et d\'étudier divers documents, avant de se rendre en Égypte, dans les régions de Gizeh, de Saqqarah et de Dahchour. Après deux mois de recherches, l\'expédition décide d\'aller se reposer quelques semaines au Kenya. Le groupe part pour un safari-photo de plusieurs jours le 3 août, et disparait. Il apparaît rapidement qu\'il s\'agit d\'un crime à caractère raciste, et plusieurs membres d\'une tribu kényane sont jugés et pendus. L\'expédition de secours dirigée par la sœur de Roger Carlyle découvre un an plus tard les restes de l\'expédition. Si les corps ne sont plus identifiables, le contenu des bagages prouve qu\'il s\'agit de l\'expédition disparue. \nQuelques années plus tard, Jackson Elias, un reporter spécialisé dans les cultes religieux d\'Afrique et d\'Asie, envoie un télégramme à son éditeur, lui indiquant qu\'il possède des informations sur l\'expédition Carlyle et qu\'il dispose de documents prouvant que certains de ses membres ne sont pas morts. Il charge son vieil ami de trouver une équipe d\'investigateurs susceptibles de l\'aider dans ses recherches, et leur donne rendez-vous dans un hôtel. La campagne commence alors que les personnages sont sur le point de le rencontrer.',
      personnage: 'Nom: Mickael Donovan. \nÂge: 37ans. \nLieu de naissance: Texas. \nLieu de résidence: San Francisco. \nRace: Humain. \nMétier: Médecin légiste. \nTravers: Drogué, fanatique de la dissection.'
    },
  }
  
  export default jeu
  