import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Cardspj = ({ details }) => {

    const game = details.nom
    
    const requireImage = chemin => {
        try {
            return (
            require(`../image/${chemin}`).default
            )
        } catch (err) {
            return (
            require(`../image/default.jpg`).default    
            )
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
    <Card style={{ width: '18rem' }}>
        <Card.Img variant="top" src={requireImage(details.image)} />
            <Card.Body>
                <Card.Title>{game}</Card.Title>
                <Card.Text className="edition">{details.edition}</Card.Text>
                <Card.Text>{details.univers}</Card.Text>
                <Link to={`/pj/${game}`}>Voir le jeu</Link>
            </Card.Body>
    </Card>
    )
};

export default Cardspj;