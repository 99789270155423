import React, { Component } from 'react'
//COMPONENTS
import Header from './components/Header'
import MenuButtons from './components/Menu-buttons'
import Cardspj from './components/Cardspj'
import Footer from './components/Footer'
//CSS
import 'bootswatch/dist/slate/bootstrap.min.css';
import './App.css'; 
//Jeux
import jeu from './jeuxPj'


class Pj extends Component {

  state = {
    jeux: jeu,
  }

  handleChangeSrc = event => {
    const src = document.body
    const srcBg = event.target.value
    src.style.backgroundImage = `url(${srcBg})`
    const policeSf = event.target.style.fontFamily
    src.style.fontFamily = policeSf  
  }

 render () {

  const { jeux } = this.state

  const cards = Object.keys(jeux)
    .map(key => <Cardspj key={key} details={jeux[key]} />)
  
  return (
    /*<div className="App" style={{ backgroundImage: `url(${backG})`, fontFamily: `${police}`}}>*/
    <div className="App" >
      <Header test='pj'/>
        <div className="choix container">
          <p className="pbg" >Envie de personnaliser, choisis ton univers :</p>
        </div> 
        <MenuButtons />
      <main>
        <div className="cards">
          {cards}
        </div>
        <div className="legrog">
          <a href="http://www.legrog.org/themes">Un autre thème ? Un autre jeu ? </a>
        </div>
      </main>
      <Footer />
    </div>
  );
 };
}

export default Pj;
