import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
//COMPONENTS
import Header from './Header'
import MenuButtons from './Menu-buttons'
import Footer from './Footer';
//CSS
import 'bootswatch/dist/slate/bootstrap.min.css';
import '../App.css'; 
//PLUGIN PERSONNALISATION
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons'
//Jeux
import jeu from '../jeuxPj'


class Perso extends Component {

    state = {
        jeux:jeu,
        game: this.props.match.params.game
    }

    render() {
        window.scrollTo(0, 0);
        const { game, jeux } = this.state
        
        const test = [
            jeux.jeux1.nom,
            jeux.jeux2.nom,
            jeux.jeux3.nom
        ]

        let jdrDescription;
        let jdrPersonnage;
        let jdrCampagne;

        switch (game) {
            case jeux.jeux1.nom:
                jdrDescription = jeux.jeux1.description
                jdrPersonnage = jeux.jeux1.personnage
                jdrCampagne = jeux.jeux1.campagne
                break;
            case jeux.jeux2.nom:
                jdrDescription = jeux.jeux2.description
                jdrPersonnage = jeux.jeux2.personnage
                jdrCampagne = jeux.jeux2.campagne
                break;
            case jeux.jeux3.nom:
                jdrDescription = jeux.jeux3.description
                jdrPersonnage = jeux.jeux3.personnage
                jdrCampagne = jeux.jeux3.campagne
                break;
            default:
                if (test.indexOf(game) === -1) {
                    return(
                        <Redirect to={'/error'}/>
                    )
                }
                break;
        }

        const description = jdrDescription
        .split('\n')
        .map(texte =>
        <p key={Math.random() + ""}>{texte}</p>
        )

        const personnage = jdrPersonnage
        .split('\n')
        .map(texte =>
        <li key={Math.random() + ""}>{<FontAwesomeIcon icon={faDiceD20} />} {texte}</li>
        )

        const campagne = jdrCampagne;

    return (
        <div className="App" >
            <Header test='pj'/>
            <MenuButtons />
            <main className="container">
                <section className="pbg">
                    <h1>Fiche Personnage : {game}</h1>
                    <article>
                        {description}
                    </article>
                    <h2>Campagne : {campagne}</h2>
                    <h3>Mon Personnage</h3>
                    <article className="left">
                        {personnage}
                    </article>
                    <Link to='/pj'>Retour vers les fiches JDR</Link>
                </section>
            </main>
            <Footer />
        </div>
        );
    }
}

export default Perso;