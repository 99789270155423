import React from 'react';
import { Link } from 'react-router-dom'
//CSS
import 'bootswatch/dist/slate/bootstrap.min.css';
import '../App.css';
import fumble from '../image/fumble.png'

const NotFound = () => {
    return (
        <main className="container">
        <div className="pbg fumble" >
            <p className="italic bold">Tu essayes de suivre le chemin invisible te menant à la vérité absolue...</p>
            <img src={fumble} alt="fumble"/>
            <p className="italic bold">Mais à la place, tu es gentillement diriger vers la case départ !</p>
            <Link to="/" className='notFound'>Retour à la case départ</Link>
        </div>
        </main>
    );
};

export default NotFound
