import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Pj from './Joueur';
import Mj from './Maitre';
import Perso from './components/Perso';
import Meneur from './components/Meneur';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider } from "react-alert";


import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'



/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

const options = {
  timeout: 5000,
  position: positions.MIDDLE
};

const Root = () => (
  <Provider template={AlertTemplate} {...options}>
  <Router>
    <Switch>
      <Route exact path='/' component={App} />
      <Route exact path='/pj/' component={Pj} />
      <Route exact path='/pj/:game' component={Perso} />
      <Route exact path='/mj/' component={Mj} />
      <Route exact path='/mj/:game' component={Meneur} />
      <Route exact path='/contact' component={Contact} />
      <Route component={NotFound} />
    </Switch>
  </Router>
  </Provider>
)

ReactDOM.render(<Root />, document.getElementById('root'))