import React from 'react';
import MenuHeader from './Menu-header'
//CSS
import 'antd/dist/antd.css';

class Header extends React.Component {
    state = {
      current: 'home',
      test: this.props.test
    };
  
    handleClick = e => {
      console.log('click ', e);
      this.setState({ current: e.key });
    };
  
    render() {
      const { current, test} = this.state;

      switch (test) {
        case 'pj':
          return (
            <header>
            <MenuHeader 
            current={test}
            changerOnglet={this.handleClick}
            />
          </header>
          )
        case 'mj':
          return (
            <header>
            <MenuHeader 
            current={test}
            changerOnglet={this.handleClick}
            />
          </header>
          )
        case 'mail':
          return (
             <header>
             <MenuHeader 
             current={test}
             changerOnglet={this.handleClick}
            />
          </header>
          )
        default:
          return (
            <header>
            <MenuHeader 
              current={current}
              changerOnglet={this.handleClick}
              />
            </header>
          );
      }
    }
  }

export default Header;