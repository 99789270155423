import React from 'react';
import { useAlert } from "react-alert";
//COMPONENTS
import Header from './Header';
import MenuButtons from './Menu-buttons';
import Footer from './Footer';
//CSS
import 'bootswatch/dist/slate/bootstrap.min.css';
import '../App.css';
//PLUGIN PERSONNALISATION
import { Form, Button } from 'react-bootstrap'; 
//MAIL
import emailjs from 'emailjs-com';
import mail from '../apikeys';


const Contact = () => {

  const alert = useAlert();

    const onSubmit=(e)=>{
      setTimeout(() => window.location.reload(false), 5500)        
      e.preventDefault()
        // Prevents default refresh by the browseremailjs.sendForm(‘gmail’, apiKeys.TEMPLATE_ID, e.target, apiKeys.USER_ID)
        emailjs.sendForm('gmail', mail.TEMPLATE_ID, e.target, mail.USER_ID)
        try {
          return(
            alert.success('Message envoyé, je vous réponds au plus vite !')
          )
      } catch (err) {
          return (
          alert.error('Erreur, réessayer s\'il vous plaît!') 
          )
      }
      
        }  

  return (
    /*<div className="App" style={{ backgroundImage: `url(${backG})`, fontFamily: `${police}`}}>*/
    <div className="App">
      <Header test="mail"/>
      <div className="choix container">
        <p className="pbg" >Envie de personnaliser, choisis ton univers :</p>
      </div> 
      <MenuButtons />
      <main className="container">
        <section className="pbg">
            <Form onSubmit={onSubmit}>
                <h1>Des questions, des infos, contacte-moi !</h1>
                <Form.Group controlId="formBasicText">
                    <Form.Label>Votre nom:</Form.Label>
                    <Form.Control type="text" name="nom" placeholder="Votre nom" required/>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Votre mail:</Form.Label>
                    <Form.Control type="email" name="mail" placeholder="Votre mail" required/>
                </Form.Group>
                <Form.Group controlId="formBasicText">
                    <Form.Label>Sujet du message:</Form.Label>
                    <Form.Control type="text" name="sujet" placeholder="Sujet du message" required/>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Votre message</Form.Label>
                    <Form.Control name="message" placeholder="Votre message" as="textarea" rows={4} required/>
                </Form.Group>
                <Button variant="primary" type="submit">Envoyer</Button>
            </Form>
        </section>
      </main>
      <Footer />
    </div>
  );
 };


export default Contact;

