// Liste de Jeu entant que Maître du jeu
const maitrise = {
    jeux1: {
      nom: 'Star Wars D6',
      edition: '2ème édition',
      image: 'swd6.jpg',
      univers: 'Science Fiction',
      description: 'Il y a bien longtemps, dans une galaxie lointaine, très lointaine... \nLe jeu de rôle Star Wars va vous permettre d\'entrer dans l\'action et le suspense de la plus grande épopée spatiale de tous les temps ! \nDécouvrez un univers plein de héros mythiques,d\'effroyables crapules, de technologies stupéfiantes et d\'extraterrestres exotiques. Luttez contre la formidable puissance du maléfique Empire galactique. Volez à bord de vaisseaux spatiaux plus rapides que la lumière. Échangez des tirs de blaster avec des soldats de choc. Livrez des duels au sabre-laser avec des guerriers Jedi corrompus par l\'Obscurité et puisez dans les ressources de la puissance mystique que l\'on appelle la FORCE. Explorez une galaxie comptant des milliards de soleils, un univers aux innombrables périls dans lequel des Rebelles luttent désespérément contre l\'oppression impériale. \nDans Star Wars, vous serez confronté à des adversaires supérieurs en nombre et à des défis impossibles. Mais pour peu que vous sachiez faire preuve de bravoure, vous triompherez de toutes les épreuves, car la FORCE sera avec vous, à jamais ! \nVous avez vu les films ? Maintenant, vivez l\'aventure !',
      joueurs:'Quöad Kylou : Falleen - Jedi \nSamael Vizla : Humain - Mandalorien \nTar Tar Boink : Gungan - Chasseur de primes \nXamus Maul : Zabrak - Commerçant d\'armes \nMatone : Humain - Pilote aguerri \nMidget : Ewok - Dompteur'
    },
    jeux2: {
      nom: 'MektonZ',
      edition: 'Mekton Zeta Plus',
      image: 'mektonz.jpg',
      univers: 'Science Fiction',
      description: 'Le jeu de rôles d\'animation Japonaise. \nImaginez des robots de combat de 15 mètres fonçant, réacteurs au maximum, au travers des immeubles de verre d\'une grande ville, se tirant des rafales de lasers dont les impacts creusent des cratères de 10 mètres et écrasant des voitures lors de leurs atterrissages ! \nPeut-être préférez-vous vivre des aventures dans l\'espace, où des princesses à la beauté éclatante et aux pouvoirs mystérieux seront sauvées de justesse d\'une mort certaine par des jeunes pilotes inconscients traversant des champs d\'astéroïdes aux commandes de leurs chasseurs, poursuivis par les sbires d\'un immonde empereur. \nMekton Z est un jeu complet de science fiction, permettant de simuler toute l\'Animation Japonaise. Vous aurez tout les règles nécessaires à la création de vos méchas que vous avez pu admirer dans vos séries préférées. Toutes les indications indispensables pour retrouver le style des plus grands films d\'animation vous sont fournies, avec tous les détails nécessaires.',
      joueurs:'Toshiro Hitsugaya : Jeune homme de 16ans - spécialisé en armement \nAsuna Saigō: Jeune fille de 16ans - Leader née \nGenji Takita : jeune homme de 16ans - spécialisé en technologie'
    },
  }
  
  export default maitrise
  