import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
//COMPONENTS
import Header from './Header'
import MenuButtons from './Menu-buttons'
import Footer from './Footer';
//CSS
import 'bootswatch/dist/slate/bootstrap.min.css';
import '../App.css'; 
//PLUGIN PERSONNALISATION
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons'
//Jeux
import maitrise from '../jeuxMj'

class Meneur extends Component {

    state = {
        jeux:maitrise,
        game: this.props.match.params.game
    }

    render() {
        
        window.scrollTo(0, 0);
        
        const { game, jeux } = this.state

        const jeu = [
            jeux.jeux1.nom,
            jeux.jeux2.nom
        ]
    
        console.log(game)
    
        let jdrDescription;
        let jdrJoueurs;
    
        switch (game) {
            case jeux.jeux1.nom:
                jdrDescription = jeux.jeux1.description
                jdrJoueurs = jeux.jeux1.joueurs
                break;
            case jeux.jeux2.nom:
                jdrDescription = jeux.jeux2.description
                jdrJoueurs = jeux.jeux2.joueurs
                break;
            default:
                if (jeu.indexOf(game) === -1) {
                    return(
                        <Redirect to={'/error'}/>
                    )
                }
                break;
        }
    
        const description = jdrDescription
            .split('\n')
            .map(texte =>
            <p key={Math.random() + ""}>{texte}</p>
            )

        const joueurs = jdrJoueurs
            .split('\n')
            .map(texte =>
            <li key={Math.random() + ""}>{<FontAwesomeIcon icon={faDiceD20} />} {texte}</li>
            )

        return (
            <div className="App" >
            <Header test='mj'/>
            <MenuButtons />
            <main className="container">
                <section className="pbg">
                    <h1>Maître du jeu à : {game}</h1>
                    <article>
                        {description}
                    </article>
                    <h2>Les personnages de mes joueurs</h2>
                    <article className="left">
                        {joueurs}
                    </article>
                    <Link to='/mj/'>Retour vers les fiches JDR</Link>
                </section>
            </main>
            <Footer />
            </div>
        );
    }
}

export default Meneur;