import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>© 2020 Par Emmanuel Rodrigues – Tous droits réservés</p>
        </footer>
    );
};

export default Footer