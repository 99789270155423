import React from 'react';
//PLUGIN PERSONNALISATION
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons'
//ROUTER
import { Link } from 'react-router-dom'

const MenuHeader = ({ current, changerOnglet }) => {
    return (
        <Menu onClick={changerOnglet} selectedKeys={[current]} mode="horizontal">
          <Menu.Item key="home"icon={<FontAwesomeIcon icon={faDiceD20} />}>
            <Link to="/">Jeu de Rôle</Link> 
          </Menu.Item>
          <Menu.Item key="pj" icon={<FontAwesomeIcon icon={faDiceD20} />}>
            <Link to="/pj">Mes Jeux de Rôle côté Joueur</Link> 
          </Menu.Item>
          <Menu.Item key="mj" icon={<FontAwesomeIcon icon={faDiceD20} />}>
          <Link to="/mj"> Mes Jeux de Rôle côté Maître du jeu</Link>
          </Menu.Item>
          <Menu.Item key="mail"icon={<FontAwesomeIcon icon={faDiceD20} />}>
            <Link to="/contact">Contact</Link> 
          </Menu.Item>
        </Menu>
    );
};

export default MenuHeader;