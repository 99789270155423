import React from 'react';
import Buttons from './Buttons';
//IMAGE - VOIR A PASSER VIA CSS
import sciencefiction from '../image/testsf.jpg'
import horror from '../image/testHorror.jpg'
import fantasy from '../image/testFantasy.jpg'
import medieval from '../image/medieval.jpg'
import dice from '../image/dice.jpg'

const MenuButtons = () => {

    const handleChangeSrc = event => {
        const src = document.body
        const srcBg = event.target.value
        src.style.backgroundImage = `url(${srcBg})`
        const policeSf = event.target.style.fontFamily
        src.style.fontFamily = policeSf  
      }

    return (
    <div className="buttons">
        <Buttons 
              className="btn btn-outline-info" 
              name="Jeux de dés"
              police="Libre Baskerville, serif"
              src={dice}
              handleChangeSrc={handleChangeSrc} 
            />
        <Buttons 
              className="btn btn-outline-primary" 
              name="Science Fiction"
              police="Orbitron, sans-serif"
              src={sciencefiction}
              handleChangeSrc={handleChangeSrc} 
            />
        <Buttons 
              className="btn btn-outline-success" 
              name="Fantastique"
              police="Snowburst One, cursive"
              src={fantasy}
              handleChangeSrc={handleChangeSrc}
            />
        <Buttons 
              className="btn btn-outline-danger" 
              name="Horreur" 
              police="Finger Paint, cursive"
              src={horror}
              handleChangeSrc={handleChangeSrc}
            />
        <Buttons 
              className="btn btn-outline-warning" 
              name="Medieval" 
              police="Uncial Antiqua, cursive"
              src={medieval}
              handleChangeSrc={handleChangeSrc}
            />
    </div>
    );
};

export default MenuButtons;